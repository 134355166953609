import {
  IonButton,
  IonContent,
  IonDatetime,
  IonHeader,
  IonPage,
  IonProgressBar,
  IonSpinner,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import Header from "../../../components/header/Header";
import StartModal from "../../../components/modals/StartModal";
import "./Business.scss";

import UploadIcon from "../../../assets/img/upload-icon.svg";
import UploadedIcon from "../../../assets/img/uploaded-icon.svg";
import { useHistory } from "react-router";
import React, { useEffect, useRef, useState } from "react";
import DashHeader from "../../../components/dash-header/DashHeader";
import LeftMenu from "../../../components/left-menu/LeftMenu";
import {
  business,
  uploadProgress,
  workshop,
  workshopSub,
} from "../../../services/data.service";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Geocode, initService } from "../../../services/location.service";
import { updatebusiness } from "../../../services/business.service";
import { getUser, getBankingInfo } from "../../../services/user.service";
import { get } from "http";
import { getWorkshopById } from "../../../services/admin.service";
import {
  updateBankingDetailsRequest,
  updateWorkshopDetails,
  updateWorkshopHours,
} from "../../../services/workshop.service";
import CustomSnackbar from "../../../components/snackbar/CustomSnackbar";

const Business: React.FC = () => {
  const [workshopInfo, setWorkshopInfo]: any = useState(null);
  const [locations, setLocations] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [banks, setBanks]: any = useState([
    { name: "Absa", id: 1 },
    { name: "FNB", id: 2 },
    { name: "Capitec", id: 3 },
    { name: "Nedbank", id: 4 },
    { name: "Investec", id: 5 },
    { name: "Tyme Bank", id: 6 },
    { name: "Discovery", id: 7 },
    { name: "African Bank", id: 8 },
    { name: "Bank Zero", id: 9 },
    { name: "Bidvest Bank", id: 10 },
    { name: "Rand Merchant Bank", id: 11 },
    { name: "Standard Bank", id: 12 },
  ]);

  const [accounts, setAccounts]: any = useState([
    { name: "Cheques", id: 1 },
    { name: "Savings", id: 2 },
    { name: "Transaction", id: 3 },
  ]);

  const [bankingInfo, setBankingInfo]: any = useState(null);

  const [isAdmin, setIsAdmin] = useState(false);

  const saveBusiness = () => {
    setShowSnackbar(true);
    setWorkshopInfo(workshopInfo);
    updateWorkshopDetails(workshopInfo)
      .then((res: any) => {
        console.log(res);
        setSnackbarMessage("Business details have been updated!");
        setTimeout(() => {
          setShowSnackbar(false);
        }, 5000); // 5000 milliseconds = 5 seconds
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const saveWorkShopHours = () => {
    const payload = { initial_hours: Math.floor(workshopInfo.initial_hours),};
    setShowSnackbar(true);
    setWorkshopInfo(workshopInfo);
    updateWorkshopHours(payload)
      .then((res: any) => {
        console.log(res);
        setSnackbarMessage("Workshop Hours updated!");
        setTimeout(() => {
          setShowSnackbar(false);
        }, 5000);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  console.log("Workshop info", workshopInfo);

  const saveBanking = () => {
    setShowSnackbar(true);
    setBankingInfo(bankingInfo);
    setSnackbarMessage("Banking details have been sent for review!");
    setTimeout(() => {
      setShowSnackbar(false);
    }, 5000); // 5000 milliseconds = 5 seconds
    updateBankingDetailsRequest({
      data: bankingInfo,
      url: window.location.origin,
    })
      .then((res: any) => {})
      .catch((err: any) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (String(window.localStorage.getItem("user")) === "workshop") {
      setIsAdmin(true);
      getBankingInfo().then((res: any) => {
        setBankingInfo(res.banking_details[0]);
      });
    } else {
      setIsAdmin(false);
    }

    getWorkshopById({}, window.localStorage.getItem("workshop_id"))
      .then((res: any) => {
        setWorkshopInfo(res.data);
      })
      .catch((d: any) => {
        console.log(d);
      });
  }, []);

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  //The function that handles hour change
  const handleHourChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(ev.target.value, 10);
    if (!isNaN(value)) {
      setWorkshopInfo((hour: any) => ({
        ...hour,
        initial_hours: value,
      }))
    }
  }

  return (
    <>
      <IonPage>
        <IonContent fullscreen>
          <DashHeader />
          <div className="container-section">
            <LeftMenu />
            <div className="right-content-section">
              <div className="header-container">
                <h6>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    className="bi bi-briefcase-fill"
                  >
                    <path
                      fillRule="evenodd"
                      d="M0 12.5A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5V6.85L8.129 8.947a.5.5 0 0 1-.258 0L0 6.85v5.65z"
                    ></path>
                    <path
                      fillRule="evenodd"
                      d="M0 4.5A1.5 1.5 0 0 1 1.5 3h13A1.5 1.5 0 0 1 16 4.5v1.384l-7.614 2.03a1.5 1.5 0 0 1-.772 0L0 5.884V4.5zm5-2A1.5 1.5 0 0 1 6.5 1h3A1.5 1.5 0 0 1 11 2.5V3h-1v-.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5V3H5v-.5z"
                    ></path>
                  </svg>
                  <span>Business</span>
                </h6>
              </div>

              {workshopInfo ? (
                <div className="content-container grey-bg">
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <div className="card mb-3">
                        <div className="card-body p-0">
                          <h6 className="card-title fw-bolder mb-3">
                            Please fill in your business contact details.
                          </h6>
                          <div className="spacer"></div>
                          <form>
                            <div className="form-group">
                              <label className="form-label fw-bolder">
                                Workshop Telephone Number
                              </label>
                              <input
                                className="form-control"
                                type="tel"
                                defaultValue={workshopInfo.phone_number}
                                onChange={(ev) => {
                                  workshopInfo.phone_number = ev.target.value;
                                }}
                              />
                            </div>
                            <div className="form-group">
                              <label className="form-label fw-bolder">
                                Workshop email address
                              </label>
                              <input
                                className="form-control"
                                type="email"
                                defaultValue={workshopInfo.email}
                                onChange={(ev) => {
                                  workshopInfo.email = ev.target.value;
                                }}
                              />
                            </div>
                            <div className="form-group">
                              <label className="form-label fw-bolder">
                                Search Address
                              </label>
                              <input
                                className="form-control"
                                defaultValue={workshopInfo.address}
                                id="location-search"
                                type="text"
                                onInput={(ev) => {
                                  // @ts-ignore
                                  initService(ev.target.value).then(
                                    (d: any) => {
                                      console.log(d);
                                      setLocations(d);
                                    }
                                  );
                                }}
                              />
                            </div>
                            {locations.length > 0
                              ? locations.map((l: any) => {
                                  console.log(l);
                                  return (
                                    <>
                                      <div
                                        className="result-list"
                                        onClick={() => {
                                          workshopInfo.address = l.description;
                                          workshopInfo.place_id = l.place_id;
                                          Geocode(l.description).then(
                                            (d: any) => {
                                              console.log(
                                                d.geometry.location.lat()
                                              );
                                              // @ts-ignore
                                              document.getElementById(
                                                "location-search"
                                                // @ts-ignore
                                              ).value = l.description;
                                              workshopInfo.latlng = [
                                                d.geometry.location.lat(),
                                                d.geometry.location.lng(),
                                              ];
                                              setLocations([]);
                                            }
                                          );
                                        }}
                                      >
                                        <p className="m-0 p-2">
                                          {l.description}
                                        </p>
                                      </div>
                                    </>
                                  );
                                })
                              : null}
                          </form>
                          <IonButton
                            color="warning"
                            size="small"
                            disabled={!isAdmin}
                            onClick={() => {
                              saveBusiness();
                            }}
                          >
                            Save
                          </IonButton>
                        </div>
                      </div>
                      <div className="card mb-3">
                        <div className="card-body p-0">
                          <h6 className="card-title fw-bolder mb-3">
                            Provide your Company registration and VAT details.
                          </h6>
                          <div className="spacer"></div>
                          <form>
                            <div className="form-group">
                              <label className="form-label fw-bolder">
                                Company Registration No
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                readOnly={true}
                                defaultValue={workshopInfo.reg_no}
                                onChange={(ev) => {
                                  workshopInfo.reg_no = ev.target.value;
                                }}
                              />
                            </div>
                            <div className="form-group">
                              <label className="form-label fw-bolder">
                                Company VAT No
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                readOnly={true}
                                defaultValue={workshopInfo.vat_no}
                                onChange={(ev) => {
                                  workshopInfo.vat_no = ev.target.value;
                                }}
                              />
                            </div>
                          </form>
                          <IonButton
                            color="warning"
                            size="small"
                            disabled={!isAdmin}
                            onClick={() => {
                              saveBusiness();
                            }}
                          >
                            Save
                          </IonButton>
                        </div>
                      </div>

                      {isAdmin && (
                        <div className="card mb-3">
                          <div className="card-body p-0">
                            <h6 className="card-title fw-bolder mb-3">
                              Let’s get you paid!&nbsp;Fill in your company
                              banking details.
                            </h6>
                            <div className="spacer"></div>
                            <form>
                              <div className="form-group">
                                <label className="form-label fw-bolder">
                                  Select Bank
                                </label>

                                <DropDownList
                                  data={banks}
                                  value={bankingInfo?.bank}
                                  textField="name"
                                  dataItemKey="id"
                                  onChange={(ev) => {
                                    setBankingInfo({
                                      ...bankingInfo,
                                      bank: ev.value,
                                    });
                                  }}
                                />
                              </div>
                              <div className="form-group">
                                <label className="form-label fw-bolder">
                                  Account Type
                                </label>
                                <DropDownList
                                  data={accounts}
                                  defaultValue={bankingInfo?.bank_account_type}
                                  textField="name"
                                  dataItemKey="id"
                                  onChange={(ev) => {
                                    bankingInfo.bank_account_type = ev.value;
                                  }}

                                  // className='custom-dropdown'
                                />
                              </div>
                              <div className="form-group">
                                <label className="form-label fw-bolder">
                                  Account No
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  defaultValue={bankingInfo?.bank_account_no}
                                  onChange={(ev) => {
                                    bankingInfo.bank_account_no =
                                      ev.target.value;
                                  }}
                                />
                              </div>
                              <div className="form-group">
                                <label className="form-label fw-bolder">
                                  Branch Code
                                </label>
                                <input
                                  className="form-control w-50"
                                  type="text"
                                  defaultValue={bankingInfo?.bank_branch_code}
                                  onChange={(ev) => {
                                    workshopInfo.bank_branch_code =
                                      ev.target.value;
                                  }}
                                />
                              </div>
                            </form>
                            <IonButton
                              color="warning"
                              size="small"
                              disabled={!isAdmin}
                              onClick={() => {
                                saveBanking();
                              }}
                            >
                              Save
                            </IonButton>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <div className="card mb-3">
                        <div className="card-body p-0">
                          <h6 className="card-title fw-bolder mb-3">
                            Workshop Rate Details.
                          </h6>
                          <div className="spacer"></div>
                          <form>
                            <div className="form-group">
                              <label className="form-label fw-bolder">
                                Rate (Rands Per Hour)
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                defaultValue={workshopInfo.rate}
                                onChange={(ev) => {
                                  workshopInfo.rate = ev.target.value;
                                }}
                              />
                            </div>
                            <div className="form-group">
                              <label className="form-label fw-bolder">
                                Rate Discount
                              </label>
                              <input
                                className="form-control w-50"
                                type="text"
                                defaultValue={workshopInfo.rate_discount}
                                onChange={(ev) => {
                                  workshopInfo.rate_discount = ev.target.value;
                                }}
                              />
                            </div>
                            <div className="form-group">
                              <label className="form-label fw-bolder">
                                Parts Discount
                              </label>
                              <input
                                className="form-control w-50"
                                type="text"
                                defaultValue={workshopInfo.parts_discount}
                                onChange={(ev) => {
                                  workshopInfo.parts_discount = ev.target.value;
                                }}
                              />
                            </div>
                          </form>
                          <IonButton
                            color="warning"
                            size="small"
                            disabled={!isAdmin}
                            onClick={() => {
                              saveBusiness();
                            }}
                          >
                            Save
                          </IonButton>
                        </div>
                      </div>

                      <div className="card mb-3">
                        <form>
                          {" "}
                          <div className="card-body p-0">
                            <h6 className="card-title fw-bolder mb-0">
                              What are your workshop operating hours?
                            </h6>
                            <div className="spacer"></div>
                            <div className="d-flex justify-content-between mb-2 align-items-center">
                              <label className="form-label fw-bolder">
                                Monday - Friday
                              </label>
                              <IonToggle
                                mode="ios"
                                checked={workshopInfo.isWeekdayWorking}
                                onIonChange={(ev: any) => {
                                  setWorkshopInfo((prevWorkshopInfo: any) => ({
                                    ...prevWorkshopInfo,
                                    isWeekdayWorking: ev.detail.checked,
                                    weekdayStartTime: ev.detail.checked
                                      ? prevWorkshopInfo.weekdayStartTime
                                      : "",
                                    weekdayEndTime: ev.detail.checked
                                      ? prevWorkshopInfo.weekdayEndTime
                                      : "",
                                  }));
                                }}
                              ></IonToggle>
                            </div>
                            {workshopInfo.isWeekdayWorking === true ? (
                              <>
                                <div className="row">
                                  <div className="col">
                                    <div className="input-group w-100">
                                      <IonDatetime
                                        displayFormat="h:mm A"
                                        pickerFormat="h:mm A"
                                        value={workshopInfo.weekdayStartTime}
                                        // defaultValue={workshopInfo.weekdayStartTime}
                                        onIonChange={(ev: any) => {
                                          setWorkshopInfo(
                                            (prevWorkshopInfo: any) => ({
                                              ...prevWorkshopInfo,
                                              weekdayStartTime: ev.detail.value,
                                            })
                                          );
                                        }}
                                      ></IonDatetime>
                                      <span className="input-group-text">
                                        to
                                      </span>
                                      <IonDatetime
                                        displayFormat="h:mm A"
                                        pickerFormat="h:mm A"
                                        value={workshopInfo.weekdayEndTime}
                                        // defaultValue={workshopInfo.weekdayEndTime}

                                        onIonChange={(ev: any) => {
                                          setWorkshopInfo(
                                            (prevWorkshopInfo: any) => ({
                                              ...prevWorkshopInfo,
                                              weekdayEndTime: ev.detail.value,
                                            })
                                          );
                                        }}
                                      ></IonDatetime>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : null}
                            <hr />
                            <div className="d-flex justify-content-between mb-2 align-items-center">
                              <label className="form-label fw-bolder">
                                Saturday
                              </label>
                              <IonToggle
                                mode="ios"
                                checked={workshopInfo.isSaturdayWorking}
                                onIonChange={(ev: any) => {
                                  setWorkshopInfo((prevWorkshopInfo: any) => ({
                                    ...prevWorkshopInfo,
                                    isSaturdayWorking: ev.detail.checked,
                                    saturdayStartTime: ev.detail.checked
                                      ? prevWorkshopInfo.saturdayStartTime
                                      : "",
                                    saturdayEndTime: ev.detail.checked
                                      ? prevWorkshopInfo.saturdayEndTime
                                      : "",
                                  }));
                                }}
                              ></IonToggle>
                            </div>
                            {workshopInfo.isSaturdayWorking === true ? (
                              <>
                                <div className="row">
                                  <div className="col">
                                    <div className="input-group w-100">
                                      <IonDatetime
                                        displayFormat="h:mm A"
                                        pickerFormat="h:mm A"
                                        value={workshopInfo.saturdayStartTime}
                                        //  defaultValue={workshopInfo.saturdayStartTime}
                                        onIonChange={(ev: any) => {
                                          setWorkshopInfo(
                                            (prevWorkshopInfo: any) => ({
                                              ...prevWorkshopInfo,
                                              saturdayStartTime:
                                                ev.detail.value,
                                            })
                                          );
                                        }}
                                      ></IonDatetime>
                                      <span className="input-group-text">
                                        to
                                      </span>
                                      <IonDatetime
                                        displayFormat="h:mm A"
                                        pickerFormat="h:mm A"
                                        value={workshopInfo.saturdayEndTime}
                                        //  defaultValue={workshopInfo.saturdayEndTime}
                                        onIonChange={(ev: any) => {
                                          setWorkshopInfo(
                                            (prevWorkshopInfo: any) => ({
                                              ...prevWorkshopInfo,
                                              saturdayEndTime: ev.detail.value,
                                            })
                                          );
                                        }}
                                      ></IonDatetime>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : null}

                            <hr />
                            <div className="d-flex justify-content-between mb-2 align-items-center">
                              <label className="form-label fw-bolder">
                                Sunday
                              </label>
                              <IonToggle
                                mode="ios"
                                checked={workshopInfo.isSundayWorking}
                                onIonChange={(ev: any) => {
                                  setWorkshopInfo((prevWorkshopInfo: any) => ({
                                    ...prevWorkshopInfo,
                                    isSundayWorking: ev.detail.checked,
                                    sundayStartTime: ev.detail.checked
                                      ? prevWorkshopInfo.sundayStartTime
                                      : "",
                                    sundayEndTime: ev.detail.checked
                                      ? prevWorkshopInfo.sundayEndTime
                                      : "",
                                  }));
                                }}
                              ></IonToggle>
                            </div>
                            {workshopInfo.isSundayWorking === true ? (
                              <div className="row">
                                <div className="col">
                                  <div className="input-group w-100">
                                    <IonDatetime
                                      displayFormat="h:mm A"
                                      pickerFormat="h:mm A"
                                      value={workshopInfo.sundayStartTime}
                                      // defaultValue={workshopInfo.sundayStartTime}

                                      onIonChange={(ev: any) => {
                                        setWorkshopInfo(
                                          (prevWorkshopInfo: any) => ({
                                            ...prevWorkshopInfo,
                                            sundayStartTime: ev.detail.value,
                                          })
                                        );
                                      }}
                                    ></IonDatetime>
                                    <span className="input-group-text">to</span>
                                    <IonDatetime
                                      displayFormat="h:mm A"
                                      pickerFormat="h:mm A"
                                      value={workshopInfo.sundayEndTime}
                                      // defaultValue={workshopInfo.sundayEndTime}

                                      onIonChange={(ev: any) => {
                                        setWorkshopInfo(
                                          (prevWorkshopInfo: any) => ({
                                            ...prevWorkshopInfo,
                                            sundayEndTime: ev.detail.value,
                                          })
                                        );
                                      }}
                                    ></IonDatetime>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <IonButton
                            color="warning"
                            size="small"
                            disabled={!isAdmin}
                            onClick={() => {
                              saveBusiness();
                            }}
                          >
                            Save
                          </IonButton>
                        </form>
                      </div>

                      {workshopInfo && (
                        <div className="card mb-3">
                        <div className="card-body p-0">
                          <h6 className="card-title fw-bolder mb-3">
                            Workshop Hours with Servisor per day.
                          </h6>
                          <div className="spacer"></div>
                          <form>
                            <div className="form-group">
                              <div className="input-group w-100">
                                <input
                                  className="form-control"
                                  type="number"
                                  min="2"
                                  step="1"
                                  defaultValue={Math.floor(workshopInfo.available_hours)}
                                  onChange={handleHourChange}
                                />
                                <span className="input-group-text">hrs</span>
                              </div>
                              {workshopInfo.initial_hours < 2 && (
                                <p className="text-danger">
                                  Minimum of 2 hours is required
                                </p>
                              )}
                            </div>
                            <p className="mb-0">
                              <strong>NOTE:</strong> 4 hours = 1 Major service
                              or 2 minor services per day.
                            </p>
                            <div className="spacer"></div>
                          </form>
                          <IonButton
                            color="warning"
                            size="small"
                            disabled={!isAdmin}
                            onClick={() => {
                              saveWorkShopHours();
                            }}
                          >
                            Save
                          </IonButton>
                        </div>
                      </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div className="k-loading-image" />
                </div>
              )}
            </div>
          </div>
          {showSnackbar && (
            <CustomSnackbar
              message={snackbarMessage}
              onClose={handleCloseSnackbar}
            />
          )}
        </IonContent>
      </IonPage>
    </>
  );
};

export default Business;
